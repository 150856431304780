import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const CheckLoginLayout = ({ children }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [uid, setUid] = useState(null);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/check-auth`, {
  //       withCredentials: true,
  //     })
  //     .then((response) => {
  //       if (response.data.isAuthenticated) {
  //         setIsAuthenticated(true);
  //         setUid(response.data.user.member_uid);
  //       } else {
  //         toast.error('로그인이 필요한 페이지입니다.');
  //         window.location.href = 'https://login.connectplay.kr/?gid=1';
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error during auth check:', error);
  //       toast.error('로그인 상태 확인 중 오류가 발생했습니다.');
  //       window.location.href = 'https://login.connectplay.kr/?gid=1';
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, [navigate]);

  useEffect(() => {
    const checkAuthAndAdmittance = async () => {
      try {
        const authResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/check-auth`,
          { withCredentials: true }
        );

        console.log('authResponse:', authResponse);

        if (!authResponse.data.isAuthenticated) {
          throw new Error('AUTHENTICATION_FAILED');
        }

        const member_uid = authResponse.data.user.member_uid;
        const rawToken = authResponse.data.rawToken;

        const parsedToken = JSON.parse(rawToken);
        const loginToken = parsedToken.login_token;

        const requestData = {
          token: loginToken,
          member_uid,
          game_type: 0,
        };

        console.log('Sending data:', requestData);

        const gameAdmittanceResponse = await axios.post(
          'https://dev-cp-api.connectplay.kr/apiv1/game-admittance/',
          requestData,
          {
            withCredentials: true,
          }
        );

        console.log('gameAdmittanceResponse:', gameAdmittanceResponse);

        if (
          gameAdmittanceResponse.data.RESULT === true &&
          gameAdmittanceResponse.data.RETURN?.success_resending_token
        ) {
          console.log('Game admittance granted');
          setUid(member_uid);
          setIsAuthenticated(true);
        } else {
          throw new Error('ADMITTANCE_FAILED');
        }
      } catch (error) {
        console.error('Error during authentication or admittance:', error);

        if (error.message === 'AUTHENTICATION_FAILED') {
          toast.error('로그인이 필요합니다.');
          setTimeout(() => {
            window.location.href = 'https://login.connectplay.kr/?gid=1';
          }, 2000);
        } else if (error.message === 'ADMITTANCE_FAILED') {
          toast.error('게임 인증정보가 유효하지 않습니다.');
          setTimeout(() => {
            window.location.href = 'https://connectplay.kr/';
          }, 2000);
        } else {
          toast.error('에러가 발생하였습니다.');
          // setTimeout(() => {
          //   window.location.href = 'https://login.connectplay.kr/?gid=1';
          // }, 2000);
        }
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthAndAdmittance();
  }, [navigate]);

  if (isLoading) {
    console.log('Loading authentication and admittance status...');
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    console.log('No authenticated user found. Rendering will be skipped.');
    return null;
  }

  console.log(
    'Authenticated and admitted user found. Rendering children components.'
  );

  return React.cloneElement(children, { uid });
};
//   if (isLoading) {
//     console.log('Loading authentication status...');
//     return <div>Loading...</div>;
//   }

//   if (!isAuthenticated) {
//     console.log('No authenticated user found. Rendering will be skipped.');
//     return null;
//   }

//   console.log('Authenticated user found. Rendering children components.');

//   return React.cloneElement(children, { uid });
// };

export default CheckLoginLayout;
