import React from 'react';
import { learnerAvailableMoveState } from '../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import { ReactComponent as Move } from '../../assets/svg/learner/move.svg';
import { ReactComponent as Hint } from '../../assets/svg/learner/hint.svg';
import { ReactComponent as Dice } from '../../assets/svg/learner/dice.svg';

const LearnerState = ({ allow = 'none' }) => {
  // TODO: 둘 중 하나만 TRUE
  const availableMoves = useRecoilValue(learnerAvailableMoveState);
  console.log('Available Moves:', availableMoves);

  const content = {
    none: {
      name: 'MOVE',
      state: 'OFF',
      message: <>게임을 진행해주세요.</>,
      icon: <Dice />,
      color: 'text-[#A6A6A6]',
    },
    move: {
      name: 'MOVE',
      state: 'ON',
      message: (
        <>
          <span className="text-secondary">{availableMoves}</span>회 이동할 수
          있어요.
        </>
      ),
      icon: <Move />,
      color: 'text-secondary',
    },
    hint: {
      name: 'HINT',
      state: 'ON',
      message: <>마커선택 후 힌트를 확인하세요.</>,
      icon: <Hint />,
      color: 'text-primary',
    },
  };

  return (
    <aside className="flex bg-[#191F2C] rounded-xl p-4 m-4 border-2 border-zinc-600 ">
      <div>{content[allow].icon}</div>
      <div className="text-left pl-4">
        <h2 className={`font-bold ${content[allow].color} text-xl`}>
          {content[allow].name} <span>{content[allow].state} </span>
        </h2>
        <p className={`text-base-200 text-lg`}>{content[allow].message}</p>
      </div>
    </aside>
  );
};

export default LearnerState;
