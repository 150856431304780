import React, { useLayoutEffect } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { selectedTeamNumberState } from '../recoil/atoms';
import useModal from '../hooks/useModal';
import useForm from '../hooks/useForm';
import usePath from '../hooks/usePath';
import InputForm from '../components/InputForm';
import FormModal from '../components/modal/FormModal';
import PrimaryButton from '../components/common/PrimaryButton';
import Radio from '../components/learner/Radio';
import { ReactComponent as XZitLogo } from '../assets/svg/main/logo.svg';
import { ReactComponent as Caution } from '../assets/svg/main/line.svg';

import toast from 'react-hot-toast';

const Home = () => {
  const setTeamNumber = useSetRecoilState(selectedTeamNumberState);
  const [searchParams] = useSearchParams();

  const { isModalOpen, openModal, closeModal } = useModal();
  const {
    form: initialSettings,
    setForm: setInitialSettings,
    handleChange,
  } = useForm();

  const { participant } = usePath();

  const navigate = useNavigate();

  // TODO: API 수정
  const validateAPI = {
    pin: 'validatePin',
    teamCount: '',
  };

  const invalidInputFeedbacks = {
    whiteSpace: '입력해주시기 바랍니다.',
    number: '숫자만 입력가능합니다.',
    pin: '기업 코드가 일치하지 않습니다.',
    // teamCount: '생성가능한 갯수를 초과하였습니다.',
    teamCountAbove: '최대 12조까지 가능합니다.',
    teamCountBelow: '2조 이상부터 가능합니다.',
    // TODO: 문구 논의
  };

  const feedbackInput = (input, isEncrypted) => {
    if (input.length === 0) return invalidInputFeedbacks.whiteSpace;
    else if (!isEncrypted && !/^[0-9]+$/.test(input))
      return invalidInputFeedbacks.number;
  };

  const feedbackTeamCount = (input) => {
    if (input > 12) return invalidInputFeedbacks.teamCountAbove;
    else if (input < 1) return invalidInputFeedbacks.teamCountBelow;
    else return '';
  };

  const validateInput = async (type, input) => {
    const feedback = feedbackInput(input, type === 'encryptedPin');
    if (feedback) return feedback;

    if (participant === 'learner') {
      if (type === 'pin') {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/setting/validatePin`,
          { params: { pin: input }, withCredentials: true }
        );

        if (data === 'Invalid PIN') return invalidInputFeedbacks.pin;
        else {
          setInitialSettings((prev) => ({
            ...prev,
            teamCount: data.count,
            levelUpScore: data.score,
          }));
        }
      } else if (type === 'teamNumber') {
        // TODO: 최대 조 번호보다 클 경우 입장 못하도록 수정
        return feedbackTeamCount(input);
      }
    } else {
      if (type === 'pin') {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/setting/validatePin`,
          { params: { pin: input }, withCredentials: true }
        );

        if (data === 'Invalid PIN') return invalidInputFeedbacks.pin;
        else {
          setInitialSettings((prev) => ({
            ...prev,
            teamCount: data.count,
            levelUpScore: data.score,
          }));
        }
      } else if (type === 'teamCount') {
        return feedbackTeamCount(input);
      }
    }
  };

  const handleClickTeacher = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/setting/updateSettings`,
      initialSettings,
      { withCredentials: true }
    );

    if (data !== 'success')
      alert('에러가 발생했습니다. 잠시 후 진행해주시기 바랍니다.');
    else navigate('game');
  };

  const handleClickLearner = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/setting/validateLearner`,
      initialSettings,
      { withCredentials: true }
    );

    // TODO: blur시 띄울지 / alert문구 변경할지
    if (data === 'teamLeaderExists')
      return toast.error(
        `${initialSettings.teamNumber}조 팀장이 이미 존재합니다.`
      );
    else if (data !== 'success') alert('다시 입력해주세요');
    else {
      setTeamNumber(initialSettings.teamNumber);
      navigate(`game`);
      toast.dismiss();
    }
    // TODO: 팀장 선택 + 특정 조에 팀장이 존재하는 경우 입장못하도록 수정
  };

  const navigateToLogin = () => {
    window.location.href = 'https://dev.connectplay.kr/signin?gid=1';
  };

  useLayoutEffect(() => {
    const param = searchParams.get('pin');
    setInitialSettings((prev) => ({ ...prev, pin: param }));
  }, [searchParams]);

  const TeacherFormModal = (
    <FormModal closeModal={closeModal} successRedirectURL={'game'}>
      <div className="mt-4">
        <InputForm
          name={'pin'}
          type="password"
          message={'기업 코드를 입력해주세요.'}
          handleChange={handleChange}
          validateInput={validateInput}
        />
        {/* TODO: DB에서 조 개수 받아오려면 1) 기업코드 focus 해제되자마자 DB 확인 2) 기업코드 단계를 별도로 뺴야함 */}
        <InputForm
          name={'teamCount'}
          message={'총 조 개수를 입력해주세요.'}
          value={initialSettings.teamCount || ''}
          handleChange={handleChange}
          validateInput={validateInput}
        />
        <InputForm
          name={'levelUpScore'}
          message={'레벨 이동 시 사용될 점수를 입력해주세요.'}
          value={initialSettings.levelUpScore || ''}
          handleChange={handleChange}
          validateInput={validateInput}
        />
        <PrimaryButton
          label={'확인'}
          handleClick={handleClickTeacher}
          // TODO: 값이 올바르지 않아도 true이도록 (현재: 빈값기준)
          disabled={
            !initialSettings.pin ||
            !initialSettings.teamCount ||
            !initialSettings.levelUpScore
          }
          additionalClass={'w-full px-2 py-2 rounded-[5px]'}
        />
      </div>
    </FormModal>
  );

  const LearnerFormModal = (
    <FormModal closeModal={closeModal}>
      <InputForm
        name={'pin'}
        message={'기업 코드를 입력해주세요'}
        type={'number'}
        inputmode={'numeric'}
        value={initialSettings.pin || ''}
        handleChange={handleChange}
        validateInput={validateInput}
      />
      <InputForm
        name={'teamNumber'}
        message={'조 번호를 입력해주세요'}
        type={'number'}
        inputmode={'numeric'}
        handleChange={handleChange}
        validateInput={validateInput}
      />
      <Radio
        name={'role'}
        radios={[
          { label: '팀장', value: 'leader' },
          { label: '게스트', value: 'guest' },
        ]}
        selectedValue={initialSettings['role']}
        handleChange={handleChange}
      />
      <PrimaryButton
        label={'확인'}
        handleClick={handleClickLearner}
        // TODO: 값이 올바르지 않아도 true이도록 (현재: 빈값기준)
        disabled={!initialSettings.pin || !initialSettings.teamNumber}
        additionalClass={'w-full px-2 py-2 rounded-[5px] mt-6'}
      />
    </FormModal>
  );

  return (
    <main
      className={
        'h-screen bg-main-brick-pattern bg-[length:100%] bg-no-repeat lg:bg-[center_top_3rem] md:bg-[center_top_8rem] md:bg-[length:80%] sm:bg-[center_top_6rem] sm:bg-[length:150%]'
      }
    >
      <div className="flex flex-col justify-between items-center">
        <div className="lg:w-[calc(100% / 1.2] md:w-[40%] sm:w-[70%] mx-auto pt-10 flex justify-center">
          <XZitLogo className={'w-[calc(100%/1.2)]'} />
        </div>

        <div className="w-full mx-auto text-center">
          {/* TODO: 가운데로 이동 */}
          <Caution
            className={
              'absolute top-[50%] w-full lg:object-cover sm:object-center'
            }
          />
          <img
            src="/XZit/main/villain.png"
            className="absolute inset-0 top-[40%] m-auto w-auto h-auto max-w-[70%] max-h-[50%] sm:max-w-[90%] sm:top-[50%]"
            alt="villain"
          />
        </div>

        <button
          className="absolute left-1/2 -translate-x-1/2 lg:max-w-[25rem] lg:bottom-12 md:bottom-24 sm:bottom-20 sm:-translate-y-1/3"
          onClick={participant === 'teacher' ? navigateToLogin : openModal}
        >
          <img
            src="XZit/main/start_button.png"
            alt="START"
            className="min-w-full"
          />
        </button>
      </div>
      {isModalOpen &&
        (participant === 'teacher' ? TeacherFormModal : LearnerFormModal)}
    </main>
  );
};

export default Home;
