import { createBrowserRouter, Navigate } from 'react-router-dom';

import App from './App';
import ErrorPage from './pages/Error';
import CheckLoginLayout from './layout/CheckLoginLayout';
import Auth from './pages/teacher/Auth';
// import Home from './pages/Home';
import Home from './pages/Home copy';
import TeacerClass from './pages/teacher/Class';
import TeacherGame from './pages/teacher/Game';
import LearnerGame from './pages/learner/Game';

const routerData = [
  {
    index: true,
    element: <Navigate to="/teacher" replace />,
  },
  {
    path: '_Auth',
    element: <Auth />,
  },
  {
    path: 'teacher',
    element: <Home />,
  },
  {
    path: 'teacher/class',
    element: (
      <CheckLoginLayout>
        <TeacerClass />
      </CheckLoginLayout>
    ),
    withAuth: true,
  },
  {
    path: 'teacher/game',
    element: <TeacherGame />,
    withAuth: false,
  },
  {
    path: 'learner',
    element: <Home />,
  },
  {
    path: 'learner/game',
    element: <LearnerGame />,
  },
];

export const routers = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: routerData.map((router) => {
      if (router.index) {
        //index
        return {
          index: router.index,
          element: router.element,
        };
      } else if (router.withAuth) {
        //로그인여부 체크해야하는 페이지
        return {
          path: router.path,
          element: <CheckLoginLayout>{router.element}</CheckLoginLayout>,
        };
      } else {
        return {
          path: router.path,
          element: router.element,
        };
      }
    }),
  },
]);
