/* global gcls */ //전역에 gcls라는 변수가 있음을 명시
import { useEffect } from 'react';

const Class = ({ uid }) => {
  console.log('Received UID in Class component:', uid);
  const classList = {
    objtarget: 'class_area',
    gid: 1,
    uid,
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/;`;

    console.log(`Cookie set: ${name}=${value}; Expires: ${expires}`);
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  useEffect(() => {
    console.log(classList);
  }, [uid]);

  // useEffect(() => {
  //   //전역변수 선언
  //   window.navigateToGame = () => {
  //     window.location.href = '/teacher/game';
  //   };
  // }, []);

  window.navigateToGame = (element) => {
    const scheduleId = element.getAttribute('data-idx');

    if (!scheduleId) {
      console.error('Schedule ID not found');
      return;
    }

    console.log('Navigating with schedule ID:', scheduleId);

    deleteCookie('schedule');

    setCookie('schedule', scheduleId, 1);

    window.location.href = '/teacher/game';
  };

  useEffect(() => {
    let script = document.querySelector(
      `script[src="https://dev-race.connectplay.kr/class.pub.js"]`
    );

    if (!script) {
      script = document.createElement('script');
      script.src = 'https://dev-race.connectplay.kr/class.pub.js';
      script.async = true;
      document.body.appendChild(script);
    }
    script.onload = () => {
      console.log('Script loaded:', script.src);

      if (typeof gcls === 'object' && gcls.classlist) {
        gcls.classlist(classList, 'navigateToGame(this)');
      }
    };
    // if (script) {
    //   script.onload = () => {
    //     gcls.classlist(classList, 'navigateToGame(this)');
    //   };
    // }
  }, []);

  return (
    <main className="flex flex-nowrap justify-center w-full">
      <div id="class_area" className="left-content"></div>
    </main>
  );
};

export default Class;
