import React, { useEffect, useLayoutEffect, useState } from 'react';
import Board from '../../components/board/Board';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  companyState,
  filterTeamLevelState,
  learnerAvailableMoveState,
  learnerClickedBoardState,
  learnerSolvedHintState,
  learnerTeamLeaderState,
  levelState,
  levelUpScoreState,
  savedScoreState,
  selectedTeamNumberState,
} from '../../recoil/atoms';
import TeamScore from '../../components/learner/TeamScore';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import TeamIcon from '../../components/common/TeamIcon';
import LearnerState from '../../components/learner/LearnerState';
import LeaderIcon from '../../components/common/LeaderIcon';
import { ReactComponent as CVLogoEng } from '../../assets/svg/logo/connectValue_eng.svg';
import useAuthErrorHandler from '../../hooks/useAuthErrorHandler';

const Game = () => {
  const handleAuthError = useAuthErrorHandler();

  // TODO: 기업명 필요없으면 삭제
  const [companyName, setCompanyName] = useRecoilState(companyState);
  const [teamNumber, setTeamNumber] = useRecoilState(selectedTeamNumberState);
  const [isLeader, setIsLeader] = useRecoilState(learnerTeamLeaderState);
  const [levelUpScore, setLevelUpScore] = useRecoilState(levelUpScoreState);
  const [teamScores, setTeamScores] = useRecoilState(savedScoreState);
  // TODO: recoil -> react query로 완전 대체할지 고민
  const [levels, setLevels] = useRecoilState(levelState);
  const [availableMoves, setAvailableMoves] = useRecoilState(
    learnerAvailableMoveState
  );
  const teamLevels = useRecoilValue(filterTeamLevelState);
  const clickedBoard = useRecoilValue(learnerClickedBoardState);

  const setSolvedHint = useSetRecoilState(learnerSolvedHintState);

  const getTeamNumber = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/setting/getTeamNumber`,
      {
        withCredentials: true,
      }
    );
    setTeamNumber(data.team_number);
  };

  const initializeSchedule = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/setting/getCompanyInfo`,
      {
        withCredentials: true,
      }
    );

    const { company_name, level_up_score } = data;

    setCompanyName(company_name || 'error');
    setLevelUpScore(level_up_score || 'error');
  };

  const initializeLevels = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/history/getLevels`,
        {
          withCredentials: true,
        }
      );
      setLevels(JSON.parse(data));
    } catch (error) {
      handleAuthError();
    }
  };

  const initializeLeader = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/learner/checkTeamLeader`,
      {
        withCredentials: true,
      }
    );
    setIsLeader(data);
  };

  // TODO: 함수모듈화
  const {
    status,
    data: allow,
    error,
  } = useQuery({
    queryKey: ['getStatusAllow'],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/allow/getStatusAllow`, {
          withCredentials: true,
        })
        .then((res) => res.data),
    refetchInterval: 5000,
  });

  const {
    status: scoresStatus,
    data: scores,
    error: scoresError,
    refetch: refetchScores,
  } = useQuery({
    queryKey: ['getScores'],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/history/getScores`, {
          withCredentials: true,
        })
        .then((res) => {
          console.log('Raw API Response:', res.data); // 응답 데이터 로그
          const parsedScores = JSON.parse(res.data.score); // `score` 필드를 파싱
          console.log('Parsed Scores:', parsedScores); // 파싱된 데이터 로그
          return parsedScores;
        }),
    refetchInterval: 5000,
  });

  const {
    status: hintStatus,
    data: hint,
    error: hintError,
  } = useQuery({
    queryKey: ['getSolvedHint'],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/history/getSolvedHint`, {
          withCredentials: true,
        })
        .then((res) => JSON.parse(res.data.solved_hint)),
    refetchInterval: 5000,
    enabled: allow?.status === 2,
  });

  const {
    status: levelStatus,
    data: fetchedLevels,
    error: levelError,
  } = useQuery({
    queryKey: ['getLevelsRealTime'],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/history/getLevels`, {
          withCredentials: true,
        })
        .then((res) => JSON.parse(res.data)),
    refetchInterval: 5000,
    enabled: (allow?.status === 1 || allow?.status === 3) && teamNumber > 0,
    onError: (error) => {
      handleAuthError();
    },
  });

  useLayoutEffect(() => {
    getTeamNumber();
    initializeSchedule();
    initializeLevels();
    initializeLeader();
  }, []);

  useEffect(() => {
    if (!scores) return;

    setTeamScores(scores);
    setAvailableMoves(Math.floor(scores[teamNumber - 1] / levelUpScore));
  }, [scores, levelUpScore]);

  useEffect(() => {
    if (hintError) return;

    setSolvedHint(hint);
  }, [hint]);

  useEffect(() => {
    if (levelError) return;
    if (!fetchedLevels) return;

    setLevels(fetchedLevels);
  }, [fetchedLevels]);

  if (status === 'error') {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="bg-game-effect-mobile bg-no-repeat bg-cover bg-center bg-opacity-50 bg-local h-full flex flex-col justify-between font-semibold">
      <header className="flex item-center justify-between px-4 py-3 grow-[1] text-lg">
        <div className="flex items-center">
          {isLeader && <LeaderIcon groupNum={teamNumber} size="sm" />}
          <TeamIcon groupNum={teamNumber} size="sm" />
          <span className="ml-1 text-xl font-semibold">조</span>
        </div>
        <TeamScore
          score={
            scoresStatus === 'success' ? scores[teamNumber - 1] : 'loading'
          }
        />
      </header>

      <section className="mx-auto grow-[3] flex flex-col items-center justify-between">
        <img
          src="/XZit/game/XZit_logo.png"
          alt="엑지트 로고"
          className="w-[80%]"
        />
        <LearnerState
          allow={
            allow?.status === 1 ? 'move' : allow?.status === 2 ? 'hint' : 'none'
          }
        />
      </section>

      {/* TODO: 특정 조에 대한 데이터만 받아서 띄우도록 수정? - 학습자페이지에서도 모든 조 데이터 가공 중 */}
      <main className="w-full grow-[8]">{levels ? <Board /> : 'error'}</main>
      <CVLogoEng className="w-full mb-5" />
    </div>
  );
};

export default Game;
